import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { useReducer } from "./store/reducer";
import TimerSession from "./components/TimerSession/TimerSession";
import { useMediaQuery } from "@material-ui/core";
import { environment } from "./environments/environment";
import theme from "./theme";
import {
  LocalizeContextProps,
  Translate as T,
  withLocalize,
} from "react-localize-redux";
import {
  availableLanguages,
  getAppTranslations,
} from "./shared/infrastructure/MultiLanguage";
import { renderToStaticMarkup } from "react-dom/server";
import Footer from "./components/Footer/Footer";
import { ModalLoading } from "./components/ModalLoading/ModalLoading";
import MainLoading from "./components/commons/MainLoading/MainLoading";
import Main from "./containers/Main";
import DeclinePayment from "./containers/DeclinePayment/DeclinePayment";
import DataVerificationFailed from "./components/DataVerificationFailed/DataVerificationFailed";
import { WrongOtpIndex } from "./containers/WrongOtpIndex/WrongOtpIndex";
import SessionExpiredIndex from "./containers/SessionExpiredIndex/SessionExpiredIndex";
import Error404 from "./components/Error404/Error404";
import TransactionSummaryIndex from "./containers/TransactionSummaryIndex/TransactionSummaryIndex";
import { TransferLoadingCard } from "./components/TransferLoadingCard/TransferLoadingCard";
import { AuthProvider } from "./shared/service/AuthService";
import { NotRequiredOtp } from "./components/NotRequiredOtp/NotRequiredOtp";
import SpeiTransactionSummaryIndex from "./containers/SpeiTransactionSummaryIndex/SpeiTransactionSummaryIndex";
import { Provider as RollbarProvider } from "@rollbar/react";
import Rollbar from "rollbar";
import packageJson from "../package.json";

const composeEnhancers = environment.devTools
  ? /* istanbul ignore next*/
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  useReducer,
  composeEnhancers(applyMiddleware(thunk))
);
type IAppProps = LocalizeContextProps;

export function App(props: IAppProps) {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: environment.rollbarId,
    addErrorContext: true,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.envName,
    payload: {
      client: {
        javascript: {
          code_version: packageJson.dependencies["@kushki/js"],
          source_map_enabled: true,
        },
      },
      environment: environment.envName,
    },
  };

  useEffect(() => {
    props.initialize({
      languages: availableLanguages,
      translation: getAppTranslations(),
      options: { renderToStaticMarkup },
    });
    props.setActiveLanguage("es");
  }, []);

  return (
    <RollbarProvider config={rollbarConfig}>
      <Suspense fallback={<MainLoading />}>
        <Provider store={store}>
          <AuthProvider>
            <div
              className="App"
              style={{
                minHeight: "100%",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <BrowserRouter>
                {useMediaQuery(theme.breakpoints.down("sm")) && (
                  <TimerSession />
                )}
                <div>
                  <Header />
                  <Switch>
                    <Route
                      path="/webcheckout/expired-session"
                      exact
                      component={SessionExpiredIndex}
                    />
                    <Route
                      path="/webcheckout/payment-declined"
                      component={DeclinePayment}
                    />
                    <Route
                      path="/webcheckout/resume"
                      component={TransactionSummaryIndex}
                    />
                    <Route
                      path="/webcheckout/:smartlinkId/speiResume"
                      component={SpeiTransactionSummaryIndex}
                    />
                    <Route
                      path="/webcheckout/not-required-otp"
                      component={NotRequiredOtp}
                    />
                    <Route
                      path="/webcheckout/:smartlinkId/loadingPse"
                      render={() => (
                        <TransferLoadingCard
                          mainLine={<T id="loadingTCard.mainLine" />}
                          secondaryLine={<T id="loadingTCard.secondaryLine" />}
                        />
                      )}
                    />
                    <Route
                      path="/webcheckout/:smartlinkId/loadingEtpay"
                      render={() => (
                        <TransferLoadingCard
                          mainLine={<T id="loadingTCard.mainLine" />}
                          secondaryLine={<T id="loadingTCard.secondaryLine" />}
                        />
                      )}
                    />
                    <Route
                      path="/webcheckout/:smartlinkId"
                      exact
                      render={() => (
                        <Main setActiveLanguage={props.setActiveLanguage} />
                      )}
                    />
                    <Route
                      path="/webcheckout/:smartlinkId/wrong-otp"
                      exact
                      component={WrongOtpIndex}
                    />
                    <Route
                      path="/error/:error"
                      component={DataVerificationFailed}
                    />
                    <Route
                      path="/webcheckout/:smartlinkId/error-404"
                      component={Error404}
                    />
                    <Route>
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </div>
                <Footer />
                <ModalLoading />
              </BrowserRouter>
            </div>
          </AuthProvider>
        </Provider>
      </Suspense>
    </RollbarProvider>
  );
}

export default withLocalize<IAppProps>(App);
